export const formatLanguage = (language: any) => {
  if (typeof language === "string") {
    return BCP47[language as keyof typeof BCP47] || language;
  } else {
    return "Unknown";
  }
};

export const LANGUAGE_SUPPORT = [
  {langId: "sq", display: "Albanian", show: false, deepl: false},
  {langId: "ar", display: "Arabic", show: true, deepl: true},
  {langId: "bn", display: "Bengali", show: true, deepl: false},
  {langId: "bg", display: "Bulgarian", show: true, deepl: true},
  {langId: "ca", display: "Catalan", show: false, deepl: false},
  {langId: "zh", display: "Chinese", show: true, deepl: true},
  {langId: "zh-CN", display: "Chinese (China)", show: false, deepl: false},
  {langId: "zh-HK", display: "Chinese (Hong Kong)", show: false, deepl: false},
  {langId: "zh-SG", display: "Chinese (Singapore)", show: false, deepl: false},
  {langId: "zh-TW", display: "Chinese (Taiwan)", show: false, deepl: false},
  {langId: "hr", display: "Croatian", show: false, deepl: false},
  {langId: "cs", display: "Czech", show: true, deepl: true},
  {langId: "da", display: "Danish", show: false, deepl: true},
  {langId: "nl", display: "Dutch", show: false, deepl: true},
  {langId: "en", display: "English", show: false, deepl: false},
  {langId: "et", display: "Estonian", show: false, deepl: true},
  {langId: "fi", display: "Finnish", show: false, deepl: true},
  {langId: "fr", display: "French", show: true, deepl: true},
  {langId: "ka", display: "Georgian", show: false, deepl: false},
  {langId: "de", display: "German", show: true, deepl: true},
  {langId: "el", display: "Greek", show: true, deepl: true},
  {langId: "gu", display: "Gujarati", show: false, deepl: false},
  {langId: "iw", display: "Hebrew", show: true, deepl: false},
  {langId: "hi", display: "Hindi", show: true, deepl: false},
  {langId: "hu", display: "Hungarian", show: true, deepl: true},
  {langId: "id", display: "Indonesian", show: true, deepl: true},
  {langId: "it", display: "Italian", show: true, deepl: true},
  {langId: "ja", display: "Japanese", show: true, deepl: true},
  {langId: "ko", display: "Korean", show: true, deepl: true},
  {langId: "lt", display: "Lithuanian", show: false, deepl: true},
  {langId: "ms", display: "Malay", show: false, deepl: false},
  {langId: "mr", display: "Marathi", show: true, deepl: false},
  {langId: "no", display: "Norwegian", show: false, deepl: false},
  {langId: "fa", display: "Persian", show: true, deepl: false},
  {langId: "pl", display: "Polish", show: true, deepl: true},
  {langId: "pt-BR", display: "Portuguese (Brazil)", show: true, deepl: true},
  {langId: "ro", display: "Romanian", show: true, deepl: true},
  {langId: "ru", display: "Russian", show: true, deepl: true},
  {langId: "sr", display: "Serbian", show: false, deepl: false},
  {langId: "sk", display: "Slovak", show: false, deepl: true},
  {langId: "sl", display: "Slovenian", show: false, deepl: true},
  {langId: "es", display: "Spanish", show: true, deepl: true},
  {langId: "sv", display: "Swedish", show: false, deepl: true},
  {langId: "tl", display: "Tagalog", show: true, deepl: false},
  {langId: "ta", display: "Tamil", show: true, deepl: false},
  {langId: "te", display: "Telugu", show: true, deepl: false},
  {langId: "th", display: "Thai", show: true, deepl: false},
  {langId: "tr", display: "Turkish", show: true, deepl: true},
  {langId: "uk", display: "Ukrainian", show: true, deepl: true},
  {langId: "ur", display: "Urdu", show: true, deepl: false},
  {langId: "vi", display: "Vietnamese", show: true, deepl: false},
];

const YT_LANG = [
  "sq",
  "ase",
  "am",
  "ar",
  "arc",
  "hy",
  "as",
  "ay",
  "az",
  "bm",
  "bn",
  "bn-IN",
  "ba",
  "eu",
  "be",
  "bh",
  "bi",
  "brx",
  "bs",
  "br",
  "bg",
  "my",
  "yue",
  "yue-HK",
  "ca",
  "chr",
  "zh",
  "zh-CN",
  "zh-HK",
  "zh-Hans",
  "zh-SG",
  "zh-TW",
  "zh-Hant",
  "cho",
  "cop",
  "co",
  "cr",
  "hr",
  "cs",
  "da",
  "doi",
  "nl",
  "nl-BE",
  "nl-NL",
  "dz",
  "en",
  "en-AU",
  "en-CA",
  "en-IN",
  "en-IE",
  "en-GB",
  "en-US",
  "eo",
  "et",
  "ee",
  "fo",
  "fj",
  "fil",
  "fi",
  "fr",
  "fr-BE",
  "fr-CA",
  "fr-FR",
  "fr-CH",
  "ff",
  "gl",
  "lg",
  "ka",
  "de",
  "de-AT",
  "de-DE",
  "de-CH",
  "el",
  "gn",
  "gu",
  "guz",
  "ht",
  "hak",
  "hak-TW",
  "bgc",
  "ha",
  "haw",
  "iw",
  "hi",
  "hi-Latn",
  "ho",
  "hu",
  "is",
  "ig",
  "id",
  "ia",
  "ie",
  "iu",
  "ik",
  "ga",
  "it",
  "ja",
  "jv",
  "kl",
  "kln",
  "kam",
  "kn",
  "ks",
  "kk",
  "km",
  "ki",
  "rw",
  "tlh",
  "kok",
  "ko",
  "ku",
  "ky",
  "lad",
  "lo",
  "la",
  "lv",
  "ln",
  "lt",
  "lu",
  "luo",
  "lb",
  "luy",
  "mk",
  "mai",
  "mg",
  "ms",
  "ms-SG",
  "ml",
  "mt",
  "mni",
  "mi",
  "mr",
  "mas",
  "mer",
  "nan",
  "nan-TW",
  "mxp",
  "lus",
  "mn",
  "mn-Mong",
  "na",
  "nv",
  "ne",
  "pcm",
  "nd",
  "nso",
  "no",
  "oc",
  "or",
  "om",
  "pap",
  "ps",
  "fa",
  "fa-AF",
  "fa-IR",
  "pl",
  "pt",
  "pt-BR",
  "pt-PT",
  "pa",
  "qu",
  "ro",
  "mo",
  "rm",
  "rn",
  "ru",
  "ru-Latn",
  "sm",
  "sg",
  "sa",
  "sat",
  "sc",
  "gd",
  "sr",
  "sr-Cyrl",
  "sr-Latn",
  "sh",
  "sdp",
  "sn",
  "scn",
  "sd",
  "si",
  "sk",
  "sl",
  "so",
  "nr",
  "st",
  "es",
  "es-419",
  "es-MX",
  "es-ES",
  "es-US",
  "su",
  "sw",
  "ss",
  "sv",
  "tl",
  "tg",
  "ta",
  "tt",
  "te",
  "th",
  "bo",
  "ti",
  "tpi",
  "tok",
  "to",
  "ts",
  "tn",
  "tr",
  "tk",
  "tw",
  "uk",
  "ur",
  "ug",
  "uz",
  "ve",
  "vi",
  "vo",
  "vro",
  "cy",
  "fy",
  "wal",
  "wo",
  "xh",
  "yi",
  "yo",
  "zu",
];

export const BCP47 = LANGUAGE_SUPPORT.map((ls) => ({
  [ls.langId]: ls.display,
})).reduce((acc, obj) => ({...acc, ...obj}), {});

export const getLangId = (language: string) => {
  return Object.keys(BCP47).find(
    (key) =>
      BCP47[key as keyof typeof BCP47].toLowerCase() ===
      language.toLocaleLowerCase(),
  );
};

var iso8601DurationRegex =
  /(-)?P(?:([.,\d]+)Y)?(?:([.,\d]+)M)?(?:([.,\d]+)W)?(?:([.,\d]+)D)?(?:T(?:([.,\d]+)H)?(?:([.,\d]+)M)?(?:([.,\d]+)S)?)?/;

export const parseISO8601Duration = (iso8601Duration: string) => {
  const matches = iso8601Duration.match(iso8601DurationRegex);

  return {
    sign: matches?.[1] == null ? "+" : "-",
    years: matches?.[2] == null ? 0 : +matches[2],
    months: matches?.[3] == null ? 0 : +matches[3],
    weeks: matches?.[4] == null ? 0 : +matches[4],
    days: matches?.[5] == null ? 0 : +matches[5],
    hours: matches?.[6] == null ? 0 : +matches[6],
    minutes: matches?.[7] == null ? 0 : +matches[7],
    seconds: matches?.[8] == null ? 0 : +matches[8],
  };
};

export const ytTime = (iso8601Duration: string) => {
  const time = parseISO8601Duration(iso8601Duration);
  if (time.hours > 0) {
    return `${time.hours}:${("" + time.minutes).padStart(2, "0")}:${(
      "" + time.seconds
    ).padStart(2, "0")}`;
  } else {
    return `${time.minutes}:${("" + time.seconds).padStart(2, "0")}`;
  }
};

export const videoLength = (iso8601Duration: string) => {
  const videoLen = parseISO8601Duration(iso8601Duration);
  return (
    videoLen.seconds +
    videoLen.minutes * 60 +
    videoLen.hours * 60 * 60 +
    videoLen.days * 60 * 60 * 24
  );
};

export const videoTimestamp = (seconds: number) => {
  if (seconds < 600) {
    return new Date(seconds * 1000).toISOString().substring(15, 19);
  }
  if (seconds < 3600) {
    return new Date(seconds * 1000).toISOString().substring(14, 19);
  }
  return new Date(seconds * 1000).toISOString().substring(11, 19);
};
